"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.indexHelper = exports.wait = exports.replaceAll = exports.capitalizeFirstCharacterOnly = void 0;
const fp_ts_1 = require("fp-ts");
const function_1 = require("fp-ts/lib/function");
const capitalizeFirstCharacterOnly = (str) => str && str[0].toUpperCase() + str.slice(1).toLocaleLowerCase();
exports.capitalizeFirstCharacterOnly = capitalizeFirstCharacterOnly;
const replaceAll = (charToReplace, replacement) => (str) => {
    const regex = new RegExp(charToReplace, "g");
    return str.replace(regex, replacement);
};
exports.replaceAll = replaceAll;
const wait = (millSeconds) => new Promise((resolve) => setTimeout(resolve, millSeconds));
exports.wait = wait;
const indexHelper = (arr) => (index) => function_1.pipe(index, fp_ts_1.either.fromPredicate((r) => r >= arr.length, (r) => r), fp_ts_1.either.map((r) => r % arr.length), fp_ts_1.either.fold((r) => r, (r) => r));
exports.indexHelper = indexHelper;
