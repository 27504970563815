"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateUntilError = void 0;
const Either_1 = require("fp-ts/lib/Either");
const pipeable_1 = require("fp-ts/lib/pipeable");
/**
 * Validates the entity until the first error occurs.
 * @param validators
 */
const validateUntilError = (...validators) => (entity) => pipeable_1.pipe(validators[0](entity), 
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore typescript doesn't believe there will be max 8 elements, since types are removed when spreading.
...[...validators].splice(1).map((v) => Either_1.chain(v)));
exports.validateUntilError = validateUntilError;
