"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.modifyBigUpLineArialReplacement = exports.replaceWithDefault = exports.modifyBigUpTextsForSnapshot = exports.createBigUpTexts = exports.createTexts = exports.getWordsFromText = exports.getTextFromWords = void 0;
const fp_ts_1 = require("fp-ts");
const function_1 = require("fp-ts/lib/function");
const pipeable_1 = require("fp-ts/lib/pipeable");
const LINE_STYLING_1 = require("../../business/LINE_STYLING");
const monocle_ts_1 = require("monocle-ts");
const big_up_text_gifs_1 = require("./big-up-text-gifs");
/**
 * Gets a words list, if its possible, fit words in a StyledLine, the words that are left over, are passed as a second parameter of  the tuple
 */
const edgeCaseHelper = (number) => (list) => pipeable_1.pipe(list, fp_ts_1.either.fromPredicate(([sentence]) => (sentence.length > 0 ? true : true), () => `First word is more than ${number} in length.`));
const getWordsUntilLimitNew = (min, max, matched = false) => (list) => pipeable_1.pipe(list, fp_ts_1.array.head, fp_ts_1.option.map((s) => s.trim()), fp_ts_1.option.chain(fp_ts_1.option.fromPredicate((e) => e.length > 0)), fp_ts_1.option.map((e) => (e + matched ? " " + e : e)), fp_ts_1.either.fromOption(() => ""), 
// either.chain(
//   either.fromPredicate(
//     (s) => s.length <= min,
//     (s) => s
//   )
// ),
fp_ts_1.either.chain(function_1.flow(fp_ts_1.either.fromPredicate((e) => pipeable_1.pipe(e.length > 0 && e.length <= max, (b) => !b), (s) => pipeable_1.pipe(list, fp_ts_1.array.dropLeft(1), getWordsUntilLimitNew(min - s.length, max - s.length, true), (r) => function_1.tuple(s + r[0], r[1])) // everything is fine
), 
// either.chain(
//   either.fromPredicate(
//     (e) => pipe(e.length <= min, (b) => !b), // last word
//     (r) => tuple(r, pipe(list, array.dropLeft(1)))
//   )
// ),
fp_ts_1.either.chain(fp_ts_1.either.fromPredicate(() => pipeable_1.pipe(!matched && list.length === 1, (b) => !b), (s) => function_1.tuple(s, pipeable_1.pipe(list, fp_ts_1.array.dropLeft(1))))), fp_ts_1.either.swap, fp_ts_1.either.mapLeft(() => ""))), fp_ts_1.either.fold((e) => function_1.tuple(e, list), (e) => e));
const getWordsUntilLimit = (min, max) => (list) => {
    const newList = [...list]; // so we don't mutate the list which is coming from the caller.
    let currentSentence = "";
    for (const word of list) {
        const length = word.length + currentSentence.length;
        if (length > max) {
            // if (!currentSentence) {
            //   currentSentence = word;
            //   newList.shift();
            // }
            break;
        }
        // needs place for the space
        if (currentSentence && length + 1 > max) {
            break;
        }
        currentSentence += " " + word;
        currentSentence = currentSentence.trim();
        newList.shift();
    }
    const result = function_1.tuple(currentSentence, newList);
    return pipeable_1.pipe(result, edgeCaseHelper(max));
};
const rangerOrd = fp_ts_1.ord.fromCompare(([x], [y]) => fp_ts_1.ord.ordNumber.compare(x, y));
const minSizeFromRangers = (rangers) => pipeable_1.pipe(rangers, fp_ts_1.array.reduce([100, 100], fp_ts_1.ord.min(rangerOrd)), (r) => r[0]);
const maxSizeFromRangers = (rangers) => pipeable_1.pipe(rangers, fp_ts_1.array.reduce([0, 100], fp_ts_1.ord.max(rangerOrd)), (r) => r[0]);
/**
 * Matches the minimum ranger that can fit the sentence and extracts the font size from that ranger.
 * @param rangers
 */
const rangerMatcher = (rangers) => (sentence) => pipeable_1.pipe(rangers, fp_ts_1.array.reduce([1000, 1000], ([accMax, accFontSize], [max, fontSize]) => {
    if (sentence.length <= max && max < accMax) {
        return function_1.tuple(max, fontSize);
    }
    else {
        return function_1.tuple(accMax, accFontSize);
    }
}));
const stylerFactory = (style) => (sentence) => pipeable_1.pipe(sentence, rangerMatcher(style.fontRangers), (ranger) => ({
    font: style.font,
    ranger,
    text: sentence,
}));
const createLineReducer = (style) => function_1.flow(getWordsUntilLimit(minSizeFromRangers(style.fontRangers), maxSizeFromRangers(style.fontRangers)), 
//getWordsUntilLimit(minSizeFromRangers(style.fontRangers), maxSizeFromRangers(style.fontRangers)),
fp_ts_1.either.map(([sentence, list]) => function_1.tuple(stylerFactory(style)(sentence), list)));
const getTextFromWords = (words) => words.join(" ");
exports.getTextFromWords = getTextFromWords;
const getWordsFromText = (text) => text.split(" ");
exports.getWordsFromText = getWordsFromText;
const styledLineLens = monocle_ts_1.Lens.fromProp();
const modifyFontRangerForSecondBigUp = ([chars, size]) => pipeable_1.pipe(size, (size) => size * LINE_STYLING_1.SECOND_BIG_UP_MULTIPLIER, Math.ceil, (size) => function_1.tuple(chars, size));
const modifyBigUpsForSecondBigUp = (isFirstBigUp) => (line) => pipeable_1.pipe(line, fp_ts_1.either.fromPredicate(() => isFirstBigUp, styledLineLens("fontRangers").modify(fp_ts_1.array.map(modifyFontRangerForSecondBigUp))), fp_ts_1.either.fold((e) => e, (e) => e));
const extractStylesFromWords = (isFirstBigUp) => (list) => pipeable_1.pipe(LINE_STYLING_1.LINE_STYLING, fp_ts_1.array.map(function_1.flow(modifyBigUpsForSecondBigUp(isFirstBigUp), createLineReducer)), (reducers) => pipeable_1.pipe(reducers[0](list), fp_ts_1.either.map(([style, list]) => function_1.tuple([style], list)), (r) => pipeable_1.pipe(fp_ts_1.array.dropLeft(1)(reducers), fp_ts_1.array.reduce(r, (e, r) => pipeable_1.pipe(e, fp_ts_1.either.chain(([styles, words]) => pipeable_1.pipe(r(words), fp_ts_1.either.map(([newStyle, newWord]) => function_1.tuple([...styles, newStyle], newWord)))))))));
const createTextsRecursion = (isFirstBigUp) => (sentence, isFirstIteration = true) => pipeable_1.pipe(sentence, exports.getWordsFromText, extractStylesFromWords(isFirstBigUp), fp_ts_1.either.chain(([styles, words]) => pipeable_1.pipe(words, exports.getTextFromWords, (sentence) => sentence
    ? pipeable_1.pipe(createTextsRecursion(isFirstBigUp)(sentence, false), fp_ts_1.either.map(([newStyles, newWords]) => function_1.tuple([...styles, ...newStyles], newWords)))
    : fp_ts_1.either.right(function_1.tuple(styles, words)))));
const createTexts = (isFirstBigUp) => (sentence) => pipeable_1.pipe(sentence, createTextsRecursion(isFirstBigUp), fp_ts_1.either.chain(fp_ts_1.either.fromPredicate(([_, remaining]) => (remaining.length ? false : true), () => `There are remaining words in the length`)), fp_ts_1.either.map((e) => e[0]), fp_ts_1.either.map(fp_ts_1.array.takeLeftWhile((r) => (r.text ? true : false))));
exports.createTexts = createTexts;
const createBigUpTexts = (isFirstBigUp) => (sentence) => pipeable_1.pipe(sentence, exports.createTexts(isFirstBigUp), fp_ts_1.either.map(big_up_text_gifs_1.includeGifs));
exports.createBigUpTexts = createBigUpTexts;
const lineLens = monocle_ts_1.Lens.fromProp();
const modifyBigUpTextsForSnapshotLens = lineLens("fontSize").modify((s) => Math.floor(s * 1.8));
const modifyBigUpTextsForSnapshot = (lines) => pipeable_1.pipe(lines, fp_ts_1.either.map(fp_ts_1.array.map(modifyBigUpTextsForSnapshotLens)));
exports.modifyBigUpTextsForSnapshot = modifyBigUpTextsForSnapshot;
const replaceWithDefault = (availableFonts) => (style) => pipeable_1.pipe(style, lineLens("font").modify(function_1.flow(fp_ts_1.option.fromPredicate((e) => availableFonts.includes(e)), fp_ts_1.option.fold(() => "arial", (f) => f))));
exports.replaceWithDefault = replaceWithDefault;
const modifyBigUpLineArialReplacement = (availableFonts) => (style) => pipeable_1.pipe(availableFonts, fp_ts_1.array.map((f) => f.toLowerCase()), (availableFonts) => pipeable_1.pipe(style, exports.replaceWithDefault(availableFonts)));
exports.modifyBigUpLineArialReplacement = modifyBigUpLineArialReplacement;
