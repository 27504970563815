"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.voiding = exports.makeTuple = exports.createEither = exports.createOption = void 0;
const fp_ts_1 = require("fp-ts");
/**
 * Because fp-ts option.none is not generic
 */
const createOption = () => fp_ts_1.option.none;
exports.createOption = createOption;
const createEither = (left) => fp_ts_1.either.left(left);
exports.createEither = createEither;
const makeTuple = (tuple) => tuple;
exports.makeTuple = makeTuple;
/**
 *  Used for console logging in the pipe, or operations in the pipe which are fire and forget.
 */
const voiding = (f) => (value) => {
    f(value);
    return value;
};
exports.voiding = voiding;
