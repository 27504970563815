"use strict";
/** All the implementations which are dependant to the browser (ex. canvas related)*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.createResizedLines = exports.splitLineToMatchWidth = void 0;
const fp_ts_1 = require("fp-ts");
const function_1 = require("fp-ts/lib/function");
const Semigroup_1 = require("fp-ts/lib/Semigroup");
const big_up_lines_1 = require("../../functions/styling/big-up-lines");
const text_metrics_1 = require("../../functions/styling/text-metrics");
const isIE_1 = require("./isIE");
const wordToLine = (line, index = -1, gifIndex = -1) => (text) => (Object.assign(Object.assign({}, line), { text, gif: index === gifIndex ? line.gif : fp_ts_1.option.none }));
const wordsSemiGroup = {
    concat: (x, y) => x + " " + y,
};
const gifSemiGroup = {
    concat: () => {
        return fp_ts_1.option.none;
    },
};
const linesSemiGroup = Semigroup_1.getStructSemigroup({
    text: wordsSemiGroup,
    gif: gifSemiGroup,
    font: {
        concat: (x) => x,
    },
    fontSize: {
        concat: (x) => x,
    },
});
const mergeLinesIfNeeded = (lines, currentLine) => function_1.pipe(lines, fp_ts_1.array.last, fp_ts_1.option.chain(fp_ts_1.option.fromPredicate((l) => l.text.length === 1 || currentLine.text.length === 1)), fp_ts_1.option.fold(() => [...lines, currentLine], (r) => function_1.pipe(lines, fp_ts_1.array.dropRight(1), (lines) => fp_ts_1.array.snoc(lines, linesSemiGroup.concat(r, currentLine)))));
// Make multiple lines of one line if it doesn't fit
const splitLineToMatchWidth = (maxWidth) => (line) => {
    const result = function_1.pipe(maxWidth, (maxWidth // might need tweaking
    ) => function_1.pipe(line, fp_ts_1.either.right, fp_ts_1.either.map(text_metrics_1.getLineLines(maxWidth)), fp_ts_1.either.chain(function_1.flow(fp_ts_1.either.fromPredicate(() => fp_ts_1.option.isSome(line.gif), (r) => r), fp_ts_1.either.map((lines) => function_1.tuple(lines, function_1.pipe(lines, fp_ts_1.array.reduceWithIndex(function_1.tuple(0, Number.MAX_SAFE_INTEGER), (index, [accIndex, accLength], { length }) => fp_ts_1.ord.ordNumber.compare(length, accLength) === -1 ? function_1.tuple(index, length) : function_1.tuple(accIndex, accLength)), (r) => r[0]))))), fp_ts_1.either.map(([lines, gifIndex]) => function_1.pipe(lines, fp_ts_1.array.mapWithIndex((index, l) => function_1.pipe(l, wordToLine(line, index, gifIndex))))), fp_ts_1.either.mapLeft(fp_ts_1.array.map(wordToLine(line))), fp_ts_1.either.fold((r) => r, (r) => r), fp_ts_1.array.reduce([], mergeLinesIfNeeded), fp_ts_1.array.map(text_metrics_1.modifyStyledLineToMatchWidth(maxWidth))));
    return result;
};
exports.splitLineToMatchWidth = splitLineToMatchWidth;
const createResizedLines = (availableFonts, maxWidth) => (lines) => function_1.pipe(lines, (r) => (isIE_1.isIE() ? r : function_1.pipe(r, fp_ts_1.array.map(big_up_lines_1.modifyBigUpLineArialReplacement(availableFonts)))), fp_ts_1.array.chain(exports.splitLineToMatchWidth(maxWidth)));
exports.createResizedLines = createResizedLines;
