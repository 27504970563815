"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.maxLength = exports.generateMaxLengthLeftMessage = exports.minLength = exports.generateMinLengthLeftMessage = exports.lengthRange = exports.generateLengthRangeLeftMessage = exports.isLengthType = exports.generateIsLengthTypeLeftMessage = void 0;
const Either_1 = require("fp-ts/lib/Either");
const validateUntilError_1 = require("../validateUntilError");
const generateIsLengthTypeLeftMessage = (entity) => 'Entity doesn\'t have a valid "length" property ';
exports.generateIsLengthTypeLeftMessage = generateIsLengthTypeLeftMessage;
const isLengthType = (entity) => typeof (entity === null || entity === void 0 ? void 0 : entity.length) !== "number"
    ? Either_1.left(exports.generateIsLengthTypeLeftMessage(entity))
    : Either_1.right(entity);
exports.isLengthType = isLengthType;
const helper = () => (validator) => validateUntilError_1.validateUntilError(exports.isLengthType, validator);
const generateLengthRangeLeftMessage = (minLength, maxLength, entity) => `Entity length must be less than ${maxLength} but no more than ${minLength}. Received ${entity.length}`;
exports.generateLengthRangeLeftMessage = generateLengthRangeLeftMessage;
const lengthRange = (minLength, maxLength) => helper()((entity) => minLength > entity.length || maxLength < entity.length
    ? Either_1.left(exports.generateLengthRangeLeftMessage(minLength, maxLength, entity))
    : Either_1.right(entity));
exports.lengthRange = lengthRange;
const generateMinLengthLeftMessage = (minLength, entity) => `Entity must have a length more than ${minLength}! Received ${entity.length}`;
exports.generateMinLengthLeftMessage = generateMinLengthLeftMessage;
const minLength = (minLength) => helper()((entity) => entity.length < minLength
    ? Either_1.left(exports.generateMinLengthLeftMessage(minLength, entity))
    : Either_1.right(entity));
exports.minLength = minLength;
const generateMaxLengthLeftMessage = (maxLength, entity) => `Entity must have a length lesser than ${maxLength}!  Received ${entity.length}`;
exports.generateMaxLengthLeftMessage = generateMaxLengthLeftMessage;
const maxLength = (maxLength) => helper()((entity) => entity.length > maxLength
    ? Either_1.left(exports.generateMaxLengthLeftMessage(maxLength, entity))
    : Either_1.right(entity));
exports.maxLength = maxLength;
