"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StrengthDecoder = exports.STRENGTH_MAX_LENGTH = exports.STRENGTH_MIN_LENGTH = exports.StrengthSentenceDecoder = exports.STRENGTH_SENTENCE_MAX_LENGTH = exports.STRENGTH_SENTENCE_MIN_LENGTH = void 0;
const tslib_1 = require("tslib");
const convertEitherFunctionToDecoder_1 = require("../convertEitherFunctionToDecoder");
const validateUntilError_1 = require("../validateUntilError");
const trimParser_1 = require("../parsers/trimParser");
const lengthValidation_1 = require("../entities/lengthValidation");
const D = tslib_1.__importStar(require("io-ts/lib/Decoder"));
const function_1 = require("fp-ts/lib/function");
const common_1 = require("../../common");
const fp_ts_1 = require("fp-ts");
exports.STRENGTH_SENTENCE_MIN_LENGTH = 3;
exports.STRENGTH_SENTENCE_MAX_LENGTH = 100;
exports.StrengthSentenceDecoder = function_1.pipe(D.string, D.intersect(convertEitherFunctionToDecoder_1.convertEitherFunctionToDecoder(validateUntilError_1.validateUntilError(lengthValidation_1.lengthRange(exports.STRENGTH_SENTENCE_MIN_LENGTH, exports.STRENGTH_SENTENCE_MAX_LENGTH)))), D.withMessage(() => `Max character limit reached.`), trimParser_1.trimParser);
exports.STRENGTH_MIN_LENGTH = 2;
exports.STRENGTH_MAX_LENGTH = 30;
exports.StrengthDecoder = function_1.pipe(D.string, D.parse((e) => D.success(common_1.capitalizeFirstCharacterOnly(e))), D.intersect(convertEitherFunctionToDecoder_1.convertEitherFunctionToDecoder(validateUntilError_1.validateUntilError(lengthValidation_1.lengthRange(exports.STRENGTH_MIN_LENGTH, exports.STRENGTH_MAX_LENGTH)))), trimParser_1.trimParser, (d) => ({ decode: function_1.flow(d.decode, fp_ts_1.either.map(common_1.capitalizeFirstCharacterOnly)) }));
