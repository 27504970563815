"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTextGif = exports.includeGifs = void 0;
const fp_ts_1 = require("fp-ts");
const Eq_1 = require("fp-ts/lib/Eq");
const function_1 = require("fp-ts/lib/function");
const pipeable_1 = require("fp-ts/lib/pipeable");
const Random_1 = require("fp-ts/lib/Random");
const LINE_STYLING_1 = require("../../business/LINE_STYLING");
const common_1 = require("../common");
const random_1 = require("../random");
const pickGif = (alreadyPicked) => (all) => pipeable_1.pipe(all, fp_ts_1.array.filter((e) => !alreadyPicked.includes(e)), 
// array.filter((e) => !alreadyPicked.includes(e)),
// either.fromPredicate(
//   (e) => (e.length ? true : false),
//   () => all
// ),
// either.fold(
//   (r) => r,
//   (r) => r
// ),
//getSample, REMOVED 15.10.2020
(r) => r[0], (r) => function_1.tuple(r, fp_ts_1.array.snoc(alreadyPicked, r)));
const shouldInvert = (atStart) => (name) => pipeable_1.pipe(!atStart, fp_ts_1.option.fromPredicate((e) => e), fp_ts_1.option.chain(fp_ts_1.option.fromPredicate(() => LINE_STYLING_1.INVERTED_GIFS.includes(name))), fp_ts_1.option.fold(() => false, () => true));
const gifFactory = (atStart) => (gif) => (style) => pipeable_1.pipe(false, (atStart // Now we want the gif to be always placed at the end
) => pipeable_1.pipe(gif, fp_ts_1.option.fromNullable, fp_ts_1.option.fold(() => fp_ts_1.option.none, (s) => fp_ts_1.option.some({
    name: s,
    atStart,
    shouldInvert: shouldInvert(atStart)(s),
})), (gif) => ({
    fontSize: style.ranger[1],
    font: style.font,
    text: style.text,
    gif,
})));
const arrangeCombinedGifs = (arePictogramFirst = true) => pipeable_1.pipe(arePictogramFirst ? function_1.tuple(LINE_STYLING_1.PICTOGRAM_GIF_NAMES, LINE_STYLING_1.TEXT_GIF_NAMES) : function_1.tuple(LINE_STYLING_1.TEXT_GIF_NAMES, LINE_STYLING_1.PICTOGRAM_GIF_NAMES), fp_ts_1.array.map(random_1.shuffleArray), ([first, second]) => pipeable_1.pipe(first, fp_ts_1.array.chainWithIndex((index, e) => pipeable_1.pipe(second[index], fp_ts_1.option.fromNullable, fp_ts_1.option.fold(() => [e], (r) => [e, r]))), (r) => r));
const gifRecursion = (elements) => {
    const combinedGifs = arrangeCombinedGifs(Random_1.randomBool());
    const recur = (index, atStart, includedGifs) => 
    //any =>
    pipeable_1.pipe(index, common_1.indexHelper(elements), fp_ts_1.either.fromPredicate((e) => LINE_STYLING_1.ALLOWED_GIF_INDEXES.includes(e), (i) => i), fp_ts_1.either.map((i) => elements[i]), fp_ts_1.either.mapLeft((i) => elements[i]), fp_ts_1.either.chain(fp_ts_1.either.fromPredicate((r) => r.ranger[0] - r.text.length > LINE_STYLING_1.ALLOWED_GIF_GAP, (e) => e)), fp_ts_1.either.map((r) => function_1.tuple(r, pickGif(includedGifs)(combinedGifs))), fp_ts_1.either.mapLeft((r) => function_1.tuple(r, includedGifs)), fp_ts_1.either.map((r) => function_1.tuple(gifFactory(atStart)(r[1][0])(r[0]), r[1][1])), fp_ts_1.either.mapLeft((r) => function_1.tuple(gifFactory(atStart)(undefined)(r[0]), r[1])), fp_ts_1.either.fold((r) => function_1.tuple(atStart, r), (r) => function_1.tuple(!atStart, r)), (r) => (index + 1 < elements.length ? [r[1][0], ...recur(index + 1, r[0], r[1][1])] : [r[1][0]]), (r) => r);
    return recur(0, false, []);
};
const appendStyleToLine = (allStyles) => (index, style) => pipeable_1.pipe(index, common_1.indexHelper(allStyles), (i) => (Object.assign(Object.assign({}, style), allStyles[i])));
const includeGifs = (lines) => pipeable_1.pipe(lines, fp_ts_1.array.mapWithIndex(appendStyleToLine(LINE_STYLING_1.LINE_STYLING)), gifRecursion);
exports.includeGifs = includeGifs;
const getTextGif = (alreadyIncluded) => pipeable_1.pipe(LINE_STYLING_1.TEXT_GIF_NAMES, fp_ts_1.array.difference(Eq_1.eqString)(alreadyIncluded), random_1.getSample, (r) => r);
exports.getTextGif = getTextGif;
