"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.areCookiesAllowed = exports.blockCookiesGlobally = exports.allowCookiesGlobally = exports.cookiesArrayToString = exports.getAllCookies = exports.getCookie = exports.setCookie = void 0;
const tslib_1 = require("tslib");
const fp_ts_1 = require("fp-ts");
const function_1 = require("fp-ts/lib/function");
const Option_1 = require("fp-ts/lib/Option");
const D = tslib_1.__importStar(require("io-ts/Decoder"));
const COOKIES_1 = require("../../constants/COOKIES");
const fp_type_1 = require("../../functions/fp-type");
/**
 * Setting a cookie in the browser
 * @param name The name of the cookie
 * @param value The value of the cookie
 * @param time The time in milliseconds
 */
const setCookie = (name, value, time) => {
    const expires = function_1.pipe(time, Option_1.fromNullable, fp_ts_1.option.map((ms) => {
        const date = new Date();
        date.setTime(date.getTime() + ms);
        return "; expires=" + date.toUTCString();
    }), fp_ts_1.option.fold(() => "", (r) => r));
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
};
exports.setCookie = setCookie;
const getCookie = (name) => {
    const nameEQ = name + "=";
    const ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ")
            c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0)
            return fp_ts_1.option.some(c.substring(nameEQ.length, c.length));
    }
    return fp_ts_1.option.none;
};
exports.getCookie = getCookie;
const getAllCookies = () => {
    const cookies = function_1.pipe(document.cookie, (s) => s.split(";"), fp_ts_1.array.map((e) => e.split("=")), fp_type_1.voiding(console.log), D.array(D.tuple(D.string, D.string)).decode, fp_ts_1.either.mapLeft(() => []), fp_ts_1.either.fold((r) => r, (r) => r));
    return cookies;
};
exports.getAllCookies = getAllCookies;
const cookiesArrayToString = (cookies) => function_1.pipe(cookies, fp_ts_1.array.reduceWithIndex("", (i, c, [name, value]) => c + (i > 0 ? ";" : "" + name + "=" + value)));
exports.cookiesArrayToString = cookiesArrayToString;
const cookieDesc = Object.getOwnPropertyDescriptor(Document.prototype, "cookie") || Object.getOwnPropertyDescriptor(HTMLDocument.prototype, "cookie");
const allowCookiesGlobally = () => {
    Object.defineProperty(document, "cookie", {
        get: function () {
            var _a;
            return (_a = cookieDesc === null || cookieDesc === void 0 ? void 0 : cookieDesc.get) === null || _a === void 0 ? void 0 : _a.call(document);
        },
        set: function (val) {
            var _a;
            return (_a = cookieDesc === null || cookieDesc === void 0 ? void 0 : cookieDesc.set) === null || _a === void 0 ? void 0 : _a.call(document, val);
        },
    });
};
exports.allowCookiesGlobally = allowCookiesGlobally;
const blockCookiesGlobally = () => {
    const cookies = function_1.flow(exports.getAllCookies, exports.cookiesArrayToString)();
    //@ts-ignore
    if (!document.__defineGetter__) {
        Object.defineProperty(document, "cookie", {
            get: function () {
                return cookies;
            },
            set: function () {
                return true;
            },
        });
    }
    else {
        //@ts-ignore
        document.__defineGetter__("cookie", function () {
            return cookies;
        });
        //@ts-ignore
        document.__defineSetter__("cookie", function () {
            return true;
        });
    }
};
exports.blockCookiesGlobally = blockCookiesGlobally;
/**
 * Get the cookie from the document.cookie and check if the user permits cookies.
 */
const areCookiesAllowed = () => function_1.pipe(exports.getCookie(COOKIES_1.COOKIE_CONSENT_NAME), fp_ts_1.option.chain(fp_ts_1.option.fromPredicate((e) => e === "true")), fp_ts_1.option.fold(() => false, () => true));
exports.areCookiesAllowed = areCookiesAllowed;
