"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PredictionDecoder = void 0;
const tslib_1 = require("tslib");
const D = tslib_1.__importStar(require("io-ts/Decoder"));
const strength_1 = require("../../functions/validators/decoders/strength");
const undefined_1 = require("../../functions/validators/decoders/undefined");
exports.PredictionDecoder = D.type({
    addedOn: D.number,
    result: undefined_1.compactUndefinedParser(D.tuple(undefined_1.fromUndefined(D.string), undefined_1.fromUndefined(D.string))),
    input: strength_1.StrengthSentenceDecoder,
    id: D.string,
});
