"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shuffleArray = exports.getSample = void 0;
const getSample = (array) => array[Math.floor(Math.random() * array.length)];
exports.getSample = getSample;
const shuffleArray = (array) => {
    const newArr = [...array];
    let currentIndex = newArr.length, temporaryValue, randomIndex;
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
        // And swap it with the current element.
        temporaryValue = newArr[currentIndex];
        newArr[currentIndex] = newArr[randomIndex];
        newArr[randomIndex] = temporaryValue;
    }
    return newArr;
};
exports.shuffleArray = shuffleArray;
