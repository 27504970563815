"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.modifyStyledLineToMatchWidth = exports.substractGifSizeFromWidth = exports.getMaxLineWidth = exports.getLineWidth = exports.getLineLines = exports.metricsSettingsFactory = void 0;
const tslib_1 = require("tslib");
const fp_ts_1 = require("fp-ts");
const pipeable_1 = require("fp-ts/lib/pipeable");
const monocle_ts_1 = require("monocle-ts");
// @ts-ignore ts-ignore no declaration
const textMetrics = tslib_1.__importStar(require("text-metrics"));
const LINE_STYLING_1 = require("../../business/LINE_STYLING");
const DEFAULT_TEXT_METRIC_FONT_SETTINGS = {
    fontFamily: "arial",
    fontSize: "100px",
    width: 99999,
    lineHeight: "1",
};
const metricsLens = monocle_ts_1.Lens.fromProp();
const metricsSettingsFactory = (maxWidth) => (style) => pipeable_1.pipe(DEFAULT_TEXT_METRIC_FONT_SETTINGS, metricsLens("fontSize").set(style.fontSize + "px"), metricsLens("fontFamily").set(style.font), metricsLens("width").set(maxWidth), fp_ts_1.either.fromPredicate(({ fontFamily }) => fontFamily === "kranafat", (r) => r), fp_ts_1.either.fold((r) => r, metricsLens("letterSpacing").set("-0.8696px")));
exports.metricsSettingsFactory = metricsSettingsFactory;
const lineLens = monocle_ts_1.Lens.fromProp();
const getLineLines = (maxWidth) => (line) => pipeable_1.pipe(exports.substractGifSizeFromWidth(line, maxWidth), (newWidth) => pipeable_1.pipe(line, lineLens("text").modify((t) => t.toUpperCase()), exports.metricsSettingsFactory(newWidth), textMetrics.init, (metrics) => metrics.lines(line.text)));
exports.getLineLines = getLineLines;
const getLineWidth = (line) => pipeable_1.pipe(line, lineLens("text").modify((t) => t.toUpperCase()), exports.metricsSettingsFactory(10000), textMetrics.init, (metrics) => metrics.width(line.text), (width) => pipeable_1.pipe(line.gif, fp_ts_1.option.fold(() => width, () => width + LINE_STYLING_1.MINIMUM_GIF_PIXELS_WIDTH)));
exports.getLineWidth = getLineWidth;
const getMaxLineWidth = (maxWidth) => (style) => pipeable_1.pipe(style, exports.metricsSettingsFactory(maxWidth), textMetrics.init, 
// eslint-disable-next-line @typescript-eslint/no-explicit-any
(metrics) => metrics.maxFontSize(style.text.toUpperCase()), // IMPORTANT! since bigups are upper case, here they are not
(r) => r, (r) => r.slice(0, -2), parseInt, fp_ts_1.option.fromPredicate((e) => e < style.fontSize), // we want to get the max font size when it is needed.
fp_ts_1.option.fold(() => style.fontSize, (r) => r));
exports.getMaxLineWidth = getMaxLineWidth;
const styleLens = monocle_ts_1.Lens.fromProp();
const substractGifSizeFromWidth = (line, maxWidth) => pipeable_1.pipe(line, styleLens("gif").get, fp_ts_1.option.fold(() => maxWidth, () => maxWidth - LINE_STYLING_1.MINIMUM_GIF_PIXELS_WIDTH));
exports.substractGifSizeFromWidth = substractGifSizeFromWidth;
const modifyStyledLineToMatchWidth = (width) => (style) => pipeable_1.pipe(style, exports.getMaxLineWidth(exports.substractGifSizeFromWidth(style, width)), (r) => pipeable_1.pipe(style, styleLens("fontSize").set(r)));
exports.modifyStyledLineToMatchWidth = modifyStyledLineToMatchWidth;
