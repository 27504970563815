"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BIG_UP_COLORS = exports.GLOBAL_COLORS = void 0;
exports.GLOBAL_COLORS = [
    { background: "#BBC2BE", text: "#70FFAA", name: "green" },
    { background: "#70FFAA", text: "#BBC2BE", name: "green" },
];
exports.BIG_UP_COLORS = [
    { background: "#BBC2BE", text: "#D9FF00", name: "yellow" },
    { background: "#FDB49E", text: "#9064FF ", name: "purple" },
    { background: "#DDB3C1", text: "#FF4C00", name: "red" },
];
