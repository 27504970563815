"use strict";
var _a, _b, _c, _d, _e;
Object.defineProperty(exports, "__esModule", { value: true });
exports.LAMBDA_GET_STRENGTH_NAMES = exports.LAMBDA_POST_FEEDBACK_SUGGESTION = exports.LAMBDA_POST_FEEDBACK = exports.LAMBDA_GET_PREDICTION = exports.LAMBDA_GET_BIG_UPS = void 0;
const thrower_1 = require("../functions/thrower");
exports.LAMBDA_GET_BIG_UPS = (_a = (process.env.LAMBDA_GET_BIG_UPS || process.env.VUE_APP_LAMBDA_GET_BIG_UPS)) !== null && _a !== void 0 ? _a : thrower_1.thrower("LAMBDA_GET_BIG_UPS must be defined");
exports.LAMBDA_GET_PREDICTION = (_b = (process.env.LAMBDA_GET_PREDICTION || process.env.VUE_APP_LAMBDA_GET_PREDICTION)) !== null && _b !== void 0 ? _b : thrower_1.thrower("LAMBDA_GET_PREDICTION must be defined");
exports.LAMBDA_POST_FEEDBACK = (_c = (process.env.LAMBDA_POST_FEEDBACK || process.env.VUE_APP_LAMBDA_POST_FEEDBACK)) !== null && _c !== void 0 ? _c : thrower_1.thrower("LAMBDA_POST_FEEDBACK must be defined");
exports.LAMBDA_POST_FEEDBACK_SUGGESTION = (_d = (process.env.LAMBDA_POST_FEEDBACK_SUGGESTION || process.env.VUE_APP_LAMBDA_POST_FEEDBACK_SUGGESTION)) !== null && _d !== void 0 ? _d : thrower_1.thrower("LAMBDA_POST_FEEDBACK_SUGGESTION must be defined");
exports.LAMBDA_GET_STRENGTH_NAMES = (_e = (process.env.LAMBDA_GET_STRENGTH_NAMES || process.env.VUE_APP_LAMBDA_GET_STRENGTH_NAMES)) !== null && _e !== void 0 ? _e : thrower_1.thrower("LAMBDA_GET_STRENGTH_NAMES must be defined");
