<template>
  <footer
    class="footer absolute bottom-0 left-0 right-0 max-w-5xl mx-auto w-full p-4"
    v-bind:style="visibleStyle"
  >
    <div>
      <FooterLeft />
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://analogfolk.com/privacy-policy "
        class="float-right border-b border-black text-xs mt-2"
        >privacy policy</a
      >
    </div>
  </footer>
</template>

<script>
import Vue from "vue";
import FooterLeft from "./FooterLeft.vue";
export default Vue.extend({
  name: "Footer",
  components: { FooterLeft },
  computed: {
    visibleStyle: function () {
      return this.$store.state.isHeaderVisible
        ? "display:block"
        : "display:none";
    },
  },
});
</script>    
<style lang="scss">
.footer {
  padding: 16px 45px;
}
@media (max-width: 768px) {
  .footer {
    padding: 12px;
  }
}
</style>