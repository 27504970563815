"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.thrower = void 0;
/**
 * Helper function for throwing an error, so we don't have to do "someNullObject ?? (()=>throw new Error())()"
 * but rather thrower()
 */
const thrower = (message) => {
    throw new Error(message);
};
exports.thrower = thrower;
