"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LINE_STYLING = exports.LINE_3_6 = exports.LINE_2_5 = exports.ALLOWED_GIF_INDEXES = exports.MINIMUM_GIF_PIXELS_WIDTH = exports.ALLOWED_GIF_GAP = exports.INVERTED_GIFS = exports.GIF_SPACE_RESERVED_SPACE_MULTIPLIER = exports.SECOND_BIG_UP_MULTIPLIER = exports.COMBINED_GIF_NAMES = exports.TEXT_GIF_NAMES = exports.PICTOGRAM_GIF_NAMES = exports.TEXT_GIF_DICTONARY = void 0;
const PICTOGRAM_GIF_DICTONARY = {
    HANDS: null,
    EYES: null,
};
exports.TEXT_GIF_DICTONARY = {
    TELLEM: null,
    YES: null,
    AWW: null,
    POW: null,
};
exports.PICTOGRAM_GIF_NAMES = Object.keys(PICTOGRAM_GIF_DICTONARY);
exports.TEXT_GIF_NAMES = Object.keys(exports.TEXT_GIF_DICTONARY);
exports.COMBINED_GIF_NAMES = [...exports.TEXT_GIF_NAMES, ...exports.PICTOGRAM_GIF_NAMES];
exports.SECOND_BIG_UP_MULTIPLIER = 1.1;
/**
 * How much we want to shrink the space so we can put the GIF.
 */
exports.GIF_SPACE_RESERVED_SPACE_MULTIPLIER = 0.8;
/**
 * Which gifs needs to be inverted.
 */
exports.INVERTED_GIFS = ["HANDS", "EYES"];
/**
 * How many free space in symbols are needed for a GIF to be placed.
 * The gap is calculated by the length of the "font ranger" minus the length of the line.
 */
exports.ALLOWED_GIF_GAP = -1;
/**
 * The minimum width of pixes that a gifs needs to render.
 */
exports.MINIMUM_GIF_PIXELS_WIDTH = 70;
/**
 * On which line array indexes it is allowed to have gifs.
 */
exports.ALLOWED_GIF_INDEXES = [0, 2, 5];
exports.LINE_2_5 = {
    font: "neuepixel",
    fontRangers: [
        [8, 58],
        [12, 35],
        [14, 29],
        [16, 26],
    ],
};
exports.LINE_3_6 = {
    font: "kranafat",
    fontRangers: [
        [8, 125],
        [10, 110],
        [12, 97],
        [14, 85],
        [16, 79],
    ],
};
exports.LINE_STYLING = [
    {
        font: "kranafat",
        fontRangers: [
            [3, 170],
            [8, 125],
            [10, 110],
            [12, 97],
            [14, 85],
            [16, 79],
        ],
    },
    Object.assign({}, exports.LINE_2_5),
    Object.assign({}, exports.LINE_3_6),
    {
        font: "syneextra",
        fontRangers: [
            [7, 43],
            [11, 28],
            [14, 22],
            [16, 19],
        ],
    },
    Object.assign({}, exports.LINE_2_5),
    Object.assign({}, exports.LINE_3_6),
];
