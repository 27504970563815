"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertEitherFunctionToDecoder = void 0;
const tslib_1 = require("tslib");
const D = tslib_1.__importStar(require("io-ts/lib/Decoder"));
const pipeable_1 = require("fp-ts/lib/pipeable");
const E = tslib_1.__importStar(require("fp-ts/lib/Either"));
const convertEitherFunctionToDecoder = (validator) => ({
    decode: (entity) => pipeable_1.pipe(entity, validator, E.fold((errs) => D.failure(entity, errs), (r) => D.success(r))),
});
exports.convertEitherFunctionToDecoder = convertEitherFunctionToDecoder;
