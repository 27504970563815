var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"min-h-screen bg-lightgreen relative",attrs:{"id":"feedback"}},[(_vm.option.isSome(_vm.errorMessage))?_c('div',[_vm._v(_vm._s(_vm.errorMessage.value))]):_c('div',{staticClass:"wrapper relative xl:min-h-screen mx-auto pt-32 font-kranafat"},[(!_vm.isFeedbackSent)?_c('div',[_vm._m(0),_c('div',{staticClass:"text mb-16 font-roboto text-lg font-bold leading-1_312"},[_vm._v(" Step by step, and with your help, our tool will get smarter. Please enter the correct strength below. ")])]):_c('div',[_c('img',{attrs:{"src":require("../../assets/images/gifs/green/THANKS_green.gif"),"alt":""}})]),_c('div',{staticClass:"autosuggest-container relative"},[_c('div',{staticClass:"autosuggest relative pb-20",class:_vm.classActive && 'active'},[_c('vue-autosuggest',{attrs:{"suggestions":_vm.filteredSuggestions,"input-props":_vm.inputPropsNew},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return (function (e) {
              e.preventDefault();
              _vm.handleSubmitButton();
            })($event)},"opened":_vm.handleOpen,"closed":_vm.handleClose,"selected":function (v) {
              if (!v) {
                return;
              }
              _vm.inputValue = v.item;
            }},model:{value:(_vm.inputValue),callback:function ($$v) {_vm.inputValue=$$v},expression:"inputValue"}}),(
            _vm.inputValue.length > _vm.STRENGTH_MIN_LENGTH &&
            _vm.either.isLeft(_vm.validatedInput)
          )?_c('div',{staticClass:"text-error mt-3"},[_vm._v(" "+_vm._s(_vm.validatedInput.left)+" ")]):_vm._e(),(!_vm.isWordAdded)?_c('div',{staticClass:"mt-2 mr-2 font-roboto text-xs font-bold text-black z-10",attrs:{"id":"word-counter"}},[_vm._v(" "+_vm._s(_vm.inputValue.length)+"/"+_vm._s(_vm.STRENGTH_MAX_LENGTH)+" ")]):_vm._e(),(_vm.isFeedbackSent)?_c('div',{staticClass:"pt-8"},[_vm._v(" BigUp.AI will add this to its knowledge. ")]):_vm._e()],1),(_vm.isWordAdded)?_c('div',{staticClass:"absolute right-0",staticStyle:{"top":"25px"}},[_c('AddedSVG',{staticClass:"inline-block"}),_c('span',{staticClass:"inline-block align-top",staticStyle:{"margin-top":"3px"}},[_vm._v("ADDED")])],1):_vm._e()]),_c('div',{staticClass:"btns-feedback relative w-full"},[(!_vm.isFeedbackSent)?_c('div',[_c('div',{staticClass:"font-roboto text-5xl leading-3"},[_vm._v("''")]),(_vm.either.isRight(_vm.sentence))?_c('div',{staticClass:"font-roboto mb-5 text-base leading-5"},[_vm._v(" "+_vm._s(_vm.sentence.right)+" ")]):_c('div',[_vm._v("Error: "+_vm._s(_vm.sentence.left))])]):_vm._e(),(!_vm.isFeedbackSent)?_c('button',{staticClass:"big-up-btn mb-2 px-2 w-full bu_button text-bugreen",class:[
          _vm.isLoading || _vm.either.isLeft(_vm.validatedInput)
            ? ["opacity-50", "cursor-not-allowed"]
            : "",
          "bu_button",
          "text-bugreen" ],attrs:{"id":"big-up-btn","disabled":_vm.isLoading || _vm.either.isLeft(_vm.validatedInput)},on:{"click":_vm.handleSubmitButton}},[_vm._v(" Submit ")]):_vm._e(),(_vm.isFeedbackSent)?_c('router-link',{staticClass:"block text-center",attrs:{"to":"strength"}},[_c('button',{staticClass:"big-up-btn bu_button text-bugreen",attrs:{"data-v-df6256be":""}},[_vm._v(" Start over ")])]):_vm._e(),(!_vm.isFeedbackSent)?_c('router-link',{staticClass:"block text-center",attrs:{"to":"strength"}},[_c('button',{staticClass:"btn-start inline-block mx-auto text-lg leading-tight font-roboto border-b-2 border-black font-bold",attrs:{"data-v-df6256be":""}},[_vm._v(" Start over ")])]):_vm._e()],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h1',{staticClass:"title text-nero font-kranafat mb-3 text-2_125 leading-1_625"},[_vm._v(" TEACH BIGUP.AI "),_c('p',[_vm._v("A LESSON")])])}]

export { render, staticRenderFns }