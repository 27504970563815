"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.trimParser = void 0;
const tslib_1 = require("tslib");
const fp_ts_1 = require("fp-ts");
const pipeable_1 = require("fp-ts/lib/pipeable");
const D = tslib_1.__importStar(require("io-ts/Decoder"));
const trimParser = (decoder) => ({
    decode: (s) => pipeable_1.pipe(s, D.string.decode, fp_ts_1.either.map((r) => r.trim()), fp_ts_1.either.chain(decoder.decode)),
});
exports.trimParser = trimParser;
