import {isIE} from "../functions/isIE"
//https://animate.style/#usage
const anims = [
  "animate__bounceIn",
  "animate__bounceInDown",
  "animate__bounceInLeft",
  "animate__bounceInRight",
  "animate__bounceInUp",
  "animate__flipInX",
  "animate__flipInY",
  "animate__lightSpeedInRight",
  "animate__lightSpeedInLeft",
  "animate__zoomIn",
];
const animsSymb = [
  "animate__rotateIn",
  "animate__flipInX",
  "animate__flipInY",
  "animate__jackInTheBox",
  "animate__zoomIn",
  // "animate__lightSpeedInRight",
  // "animate__lightSpeedInLeft"
];
const WORD_WAITING_TIME = 480

const animElment = (elmsArr, i, randomAnim, ms) => {
  const elem = elmsArr[i]
    elem.style.setProperty("opacity",0)
  ms = ms * i;
  setTimeout(function () {
    elem.style.setProperty("opacity",1)
    elem.classList.add("animate__animated", randomAnim);
  }, ms);
};
const animElment2 = (elmsArr, i, randomAnim, ms) => {
  const elem =elmsArr[i] 
  elem.style.setProperty("opacity",0)
  setTimeout(function () {
    elem.style.setProperty("opacity",1)
    elem.classList.add("animate__animated", randomAnim);
  }, ms);
};

const animWords = () => {
  let elms = document.getElementsByClassName("js-anim");
  for (let i = 0; i < elms.length; i++) {
    let randomAnim = anims[Math.floor(Math.random() * anims.length)];
    animElment(elms, i, randomAnim, WORD_WAITING_TIME);
  }
  return elms.length
};
const animSymbs = (elementCount) => {
  let elmsSymb = document.getElementsByClassName("js-anim-symb");
  for (let i = 0; i < elmsSymb.length; i++) {
    let randomAnimSymb =
      animsSymb[Math.floor(Math.random() * animsSymb.length)];
    let ms = 500 + WORD_WAITING_TIME * (elementCount - 1);
    animElment2(elmsSymb, i, randomAnimSymb, ms);
  }
};
export const runAnimations = () => {
  if (isIE()) {
    return;
  }
  try {
    const length = animWords();
  animSymbs(length);
  }
  catch (err) {
    console.log("Saving before animations are finished?",err)
  }
};
