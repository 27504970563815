var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[(_vm.either.isRight(this.typedLines))?_c('div',{staticClass:"py-8 leading-none"},_vm._l((this.typedLines.right.entries()),function(ref){
var index = ref[0];
var line = ref[1];
return _c('div',{key:line.text + line.font + line.fontSize,style:("font-size:" + line.fontSize + "px;")},[_c('div',{staticClass:"items-baseline",class:[
            {
              'big-up-right':
                (_vm.isSave || !_vm.isMac || !_vm.isMobileOrTabled) &&
                line.font !== 'neuepixel',
            },
            { 'pb-2': line.font === 'syneextra' },
            {
              'pb-1':
                line.font === 'kranafat' && _vm.lines.right[index + 1]
                  ? _vm.lines.right[index + 1].font !== 'kranafat'
                  : false,
            },
            { 'tracking-kranafat': line.font === 'kranafat' },
            {
              '-mt-2 pb-3 lg:mt-0 lg:pb-0':
              (line.font === 'neuepixel' &&
                _vm.lines.right[index - 1] &&
                _vm.lines.right[index - 1].font === 'kranafat' &&
                _vm.lines.right[index + 1] &&
                _vm.lines.right[index + 1].font === 'kranafat')
            },
            {'lg:mt-0 lg:pb-4': (line.font === 'neuepixel' &&
                _vm.isMac &&
                _vm.lines.right[index - 1] &&
                _vm.lines.right[index - 1].font === 'kranafat' &&
                _vm.lines.right[index + 1] &&
                _vm.lines.right[index + 1].font === 'kranafat') },

            "uppercase flex font-" + line.font ]},[(_vm.option.isSome(line.gif) && line.gif.value.atStart)?_c('span',{staticClass:"flex h-075",class:{ 'js-anim-symb': _vm.shouldAnimate }},[_c('img',{staticClass:"w-auto h-full",class:{ invert: line.gif.value.shouldInvert },attrs:{"src":require(("../assets/images/gifs/" + (_vm.parsedColorPair.name) + "/" + (line.gif.value.name) + "_" + (_vm.parsedColorPair.name) + ".gif"))}})]):_vm._e(),_c('span',{staticClass:"flex max-w-full whitespace-no-wrap",class:{ 'js-anim': _vm.shouldAnimate }},[_vm._v(_vm._s(line.text))]),(_vm.option.isSome(line.gif) && !line.gif.value.atStart)?_c('span',{staticClass:"flex h-0.75e pl-4",class:{ 'js-anim-symb': _vm.shouldAnimate }},[_c('img',{staticClass:"w-auto h-full",class:{ invert: line.gif.value.shouldInvert },attrs:{"src":require(("../assets/images/gifs/" + (_vm.parsedColorPair.name) + "/" + (line.gif.value.name) + "_" + (_vm.parsedColorPair.name) + ".gif"))}})]):_vm._e()])])}),0):_c('div',[_vm._v("Error parsing sentence "+_vm._s(_vm.typedLines.left))])])])}
var staticRenderFns = []

export { render, staticRenderFns }