"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.compactUndefinedParser = exports.fromUndefined = void 0;
const tslib_1 = require("tslib");
const E = tslib_1.__importStar(require("fp-ts/lib/Either"));
const D = tslib_1.__importStar(require("io-ts/lib/Decoder"));
const O = tslib_1.__importStar(require("fp-ts/lib/Option"));
const function_1 = require("fp-ts/lib/function");
const fromUndefined = (decoder) => ({
    decode: function_1.flow(O.fromNullable, O.fold(() => D.success(undefined), decoder.decode)),
});
exports.fromUndefined = fromUndefined;
/**
 * Decoding
 * @param tuple The decoder that must be type of tuple
 */
const compactUndefinedParser = (tuple) => ({
    decode: function_1.flow(tuple.decode, E.map((r) => r.filter((e) => e !== undefined))),
});
exports.compactUndefinedParser = compactUndefinedParser;
