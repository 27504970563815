var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"bg-lightgreen pb-3_437 px-3 h-screen",attrs:{"id":"strength"}},[_c('div',{staticClass:"wrapper pt-32 relative h-full"},[_c('h1',{staticClass:"font-kranafat font-bold block uppercase leading-8 mb-16 text-2_125",class:this.input.length && 'textarea-margin'},[_vm._v(" Tell us about a strength... "),_vm._m(0)]),_c('div',[_c('div',{staticClass:"font-roboto font-bold clearfix",class:{ 'text-error': _vm.either.isLeft(_vm.validatedInputLength) }},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.input),expression:"input"}],staticClass:"strenght-input block w-full bg-transparent text-lg tracking-wider font-bold text-bold py-5 px-5 pb-0 text-lg",style:({ height: ("" + (this.height)) }),attrs:{"id":"strength_input","name":"textarea","placeholder":"e.g I can talk to people easily."},domProps:{"value":(_vm.input)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.input=$event.target.value},function (e) { return _vm.handleText(e); }],"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return (function (e) {
              e.preventDefault();
              _vm.submit();
            })($event)}}}),_vm._v(" "),_c('div',{staticClass:"mt-5 w-full bg-black",staticStyle:{"height":"2px"}}),_c('div',{staticClass:"word-count float-right text-lg text-black font-bold pt-2"},[_vm._v(" "+_vm._s(_vm.wordCount)+" ")]),(_vm.either.isLeft(_vm.validatedInputLength))?_c('div',{staticClass:"text-error mt-2"},[_vm._v(" "+_vm._s(_vm.validatedInputLength.left)+" ")]):_vm._e()])]),_c('div',{staticClass:"text-error"},[_vm._v(_vm._s(_vm.errorMessage))]),_c('button',{staticClass:"bu_button text-bugreen absolute pb-6 left-0 bottom-0 mb-2 z-50",class:[
        _vm.either.isLeft(_vm.validatedInput)
          ? ["opacity-50", "cursor-not-allowed"]
          : "",
        "bu_button",
        "text-bugreen" ],attrs:{"id":"big-up-btn","disabled":_vm.either.isLeft(_vm.validatedInput)},on:{"click":_vm.submit}},[_vm._v(" Big me up ")])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text font-roboto font-bold text-lg pt-3 normal-case"},[_vm._v(" BigUp.AI will analyse your words, identify a strength "),_c('br',{staticClass:"breakline"}),_vm._v(" and help you express it with impact. ")])}]

export { render, staticRenderFns }